export default {
  "general": {
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
    "abort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswahl"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
    "mainParticipant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verantwortlicher"])},
    "mainParticipantShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verantw."])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzwerk"])},
    "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
    "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt"])},
    "projectParticipant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektteilnehmer"])},
    "projectLeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektleiter"])},
    "roleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollentyp"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erzeugt"])},
    "designation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
    "masterDataSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stammdatensatz"])},
    "object": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekt"])},
    "objectPlanner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektblatt"])},
    "route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strecke"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle"])},
    "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollen"])},
    "subProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektstatus"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kürzel"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zu"])},
    "realm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fachbereich"])},
    "realmShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FB"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionen"])},
    "projectHolidays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektfeiertage"])},
    "reviewCycleInstance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfblatt"])},
    "reviewCycleNode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knoten"])},
    "reviewCyclePhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phase"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfer"])},
    "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfung"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnis"])},
    "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maßstab"])},
    "titleContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel / Inhalt"])},
    "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochgeladen"])},
    "redlining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphische Planprüfung"])},
    "button": {
      "searchCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen"])},
      "resetSearchCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchfelder leeren"])},
      "collapseSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchfelder verbergen"])},
      "openSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchfelder anzeigen"])}
    },
    "column": {
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])}
    },
    "notYetSearched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch nichts gesucht"])},
    "searchRuns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Suche läuft..."])},
    "searchCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche abgebrochen"])},
    "entries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einträge"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
    "deputies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellvertreter"])},
    "deputy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellvertreter"])},
    "contractor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auftragnehmer"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "contractorNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auftragnehmernr."])},
    "email": {
      "sendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail senden"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail Adresse"])},
      "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhalt"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betreff"])}
    },
    "blockedStatuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektstatus sperren"])},
    "allowedSubProjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlaubte Projektstati"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "saveAndExit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern und Beenden"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["— Alle —"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["— Keine —"])},
    "planner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planer"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "attribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigenschaft"])},
    "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anforderung"])},
    "acceptance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annahme"])},
    "requestedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["angefordert durch"])},
    "requestedFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["angefordert für"])},
    "planNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plannummer"])},
    "alternativePlanNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternative Plannummer"])},
    "reviewProtocol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfprotokoll"])},
    "reviewDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfdetails"])},
    "documentReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planprüfung"])},
    "documentReferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwendete Unterlagen"])},
    "documentReferenceHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwendete Unterlagen für den gegenständlichen Plan"])},
    "referenceNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftszahl"])},
    "documentUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planupload"])},
    "documentUploadRevision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Planrevision"])},
    "downloadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei downloaden"])},
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signieren"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu versuchen"])},
    "salutation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anrede"])},
    "salutationMale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herr"])},
    "salutationFemale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frau"])},
    "givenName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "surName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
    "localityName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staat"])},
    "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anhang"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "fields": {
      "quickSearchCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofortfilter"])}
    },
    "delayFormat": {
      "upcoming": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["heute"]), _normalize(["in 1 Tag"]), _normalize(["in ", _interpolate(_named("n")), " Tagen"])])},
      "delayed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["heute"]), _normalize(["1 Tag verspätet"]), _normalize([_interpolate(_named("n")), " Tage verspätet"])])}
    },
    "projectStatus": {
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
      "INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
      "UNFINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird erstellt"])}
    },
    "projectAccess": {
      "INVISIBLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nicht sichtbar"])},
      "READ_ONLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nur lesen"])},
      "READ_WRITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lesen/schreiben"])},
      "INVISIBLE_FORNONADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nicht sichtbar ausser Admin"])},
      "READ_ONLY_FORNONADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nur lesen ausser Admin"])}
    },
    "export": {
      "pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pdf-Export"])},
      "xls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xls-Export"])}
    },
    "error": {
      "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Es ist ein technischer Fehler aufgetreten.  Bitte konsultieren Sie den zuständigen Support, und geben Sie den aktuellen Zeitstempel ", _interpolate(_named("timestamp")), " dabei an."])},
      "longRunningMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Es ist ein technischer Fehler aufgetreten.  Bitte konsultieren Sie den zuständigen Support, und geben Sie den aktuellen Zeitstempel ", _interpolate(_named("timestamp")), " an.\n\n(Ausnahme: Falls davor die Seite im Browser neu geladen wurde, wurden möglicherweise lediglich vorherige Abfragen abgebrochen)"])},
      "passwordMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Es ist ein Fehler aufgetreten.  Bitte prüfen Sie, ob Sie das korrekte Passwort eingegeben haben.  Falls das Passwort Ihrer Meinung nach korrekt ist können Sie den zuständigen Support kontaktieren.  Bitte geben Sie dabei den aktuellen Zeitstempel ", _interpolate(_named("timestamp")), " an."])}
    }
  },
  "libs": {
    "clazzesVue": {
      "loginDialog": {
        "dialogCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung c.des Planprüfung"])}
      }
    }
  },
  "login": {
    "passwordForgottenLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen - hier neu anfordern"])}
  },
  "addressOutput": {
    "personal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["z.Hd. ", _interpolate(_named("givenName")), " ", _interpolate(_named("surName"))])}
  },
  "app": {
    "infoMessagesDialogTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen"])},
    "newPasswordTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])},
    "newPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])},
    "requestUserPolicyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Benutzerrichtlinie unterschreiben"])}
  },
  "newPassword": {
    "oldPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort alt"])},
    "newPassword1Label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort neu"])},
    "newPassword2Label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort neu bestätigen"])},
    "questionLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheitsfrage"])},
    "answerLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antwort auf Sicherheitsfrage"])},
    "oldPasswordMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben sie ihr altes Passwort ein."])},
    "oldPasswordWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsches altes Passwort"])},
    "newPasswordInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiges Passwort"])},
    "newPasswordTooShort": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das Passwort muss mindestens ", _interpolate(_named("minLength")), " Zeichen lang sein."])},
    "newPasswordLackingDiversity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort muss Zeichen aus mindestens drei der nachfolgenden Kategorien enthalten: Kleinbuchstaben, Großbuchstaben, Sonderzeichen, Zahlen."])},
    "newPasswordOldPw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alte Passwörter dürfen nicht wiederverwendet werden."])},
    "newPassword1Missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben sie ein neues Passwort ein"])},
    "newPassword2Missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wiederholen sie das neue Passwort"])},
    "newPassword2NotIdentical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die beiden Passworteingaben sind nicht identisch"])},
    "passwordIllegalCharsWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort enthält nicht erlaubte Zeichen.  Erlaubt sind: Groß- und Kleinbuchstaben (inklusive Umlaute und ß), Ziffern, übliche Satz- und Sonderzeichen (d.h. .-_/!?,:§$%&€) sowie Klammern.  Nicht erlaubt sind insbesondere Leerzeichen."])},
    "questionMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie eine Sicherheitsfrage an."])},
    "answerMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie die Antwort auf die Sicherheitsfrage an."])},
    "answerTooShort": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Antwort auf die Sicherheitsfrage muss mindestens ", _interpolate(_named("minLength")), " Zeichen haben."])},
    "answerInvalidCharacters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Antwort auf die Sicherheitsfrage darf nur die Zeichen a-z in Klein- und Großschrift, Ziffern, Minuszeichen, Unterstriche und Umlaute enthalten.  Andere Zeichen sind nicht zulässig."])},
    "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die maximale Gültigkeitsdauer Ihres Passwortes beträgt ", _interpolate(_named("maxAge")), " Tage und wird in ", _interpolate(_named("remainingDays")), " Tagen ablaufen. Sie müssen einen neues Passwort wählen, um sich nach dieser Frist weiter anmelden zu können. Im Zuge dessen müssen auch die Sicherheitsfrage/-antwort erneut eingegeben werden."])}
  },
  "requestUserPolicy": {
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Benutzerrichtlinie vorhanden."])},
    "bodyFirstLine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie sollten vor kurzem ein mail mit der neuen Benutzerrichtlinie als Anhang erhalten haben. Bitte senden Sie diese unterschrieben bis zum ", _interpolate(_named("deadline")), " an die im Dokument angegebene e-mail Adresse zurück. Andernfalls wird nach Ablauf dieser Frist der Zugang gesperrt."])},
    "bodySingleLink": (ctx) => {const { normalize: _normalize, linked: _linked, interpolate: _interpolate, named: _named } = ctx;return _normalize([_linked("requestUserPolicy.bodyFirstLine"), "", "\n\nSie können die Benutzerrichtlinie mit folgendem link jetzt herunterladen ", _interpolate(_named("link"))])},
    "bodyMultipleLink": (ctx) => {const { normalize: _normalize, linked: _linked, interpolate: _interpolate, named: _named } = ctx;return _normalize([_linked("requestUserPolicy.bodyFirstLine"), "", "\n\nSie können ", _interpolate(_named("count")), " Benutzerrichtlinien für Ihre Organisationszugehörigkeiten mit folgendem link jetzt herunterladen ", _interpolate(_named("link"))])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerrichtlinie herunterladen"])}
  },
  "about": {
    "infoName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c.des® concurrent design - Planprüfung und Planmanagement"])},
    "infoVersionLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
    "infoRevisionLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revision"])},
    "infoDateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "infoDeveloperLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konzept und Entwicklung"])},
    "infoDeveloper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ev-i Informationstechnologie GmbH, 6020 Innsbruck"])}
  },
  "contextLine": {
    "contextLineCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontext"])},
    "buekContextLineCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BÜK-Kontext"])},
    "missingOrganisationPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen eine Organisation angeben."])},
    "missingNetwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen ein Netzwerk angeben."])},
    "missingProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen ein Projekt angeben."])},
    "missingSubProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen einen Projektstatus angeben."])}
  },
  "mainMenu": {
    "adminCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
    "buekCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auflagen"])},
    "documentReviewCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planprüfung"])},
    "helpCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfe/Infos"])},
    "buekSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auflagen"])},
    "documentsSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pläne / Dokumente"])},
    "globalSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global"])},
    "networkSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzwerk"])},
    "notificationSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bescheide / Auflagen"])},
    "otherSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstiges"])},
    "projectSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt"])},
    "aboutCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über"])},
    "caCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CA"])},
    "certificateCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zertifikate"])},
    "consortiumCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argen"])},
    "documentListCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planver­zeichnis"])},
    "emailCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mails"])},
    "labelTemplateCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musterplan­köpfe"])},
    "infoCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
    "instanceCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfblätter"])},
    "jobCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobs"])},
    "globalRolesCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Globale Rollen"])},
    "manualCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hand­bücher"])},
    "masterDataCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stamm­daten"])},
    "masterDataCaptionOld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stamm­daten Alt"])},
    "labelCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muster­plan­köpfe"])},
    "networkCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzwerke"])},
    "networkCaptionOld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzwerke Alt"])},
    "objectCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekte"])},
    "objectCaptionOld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekte Alt"])},
    "organisationCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organi­sationen"])},
    "originalsCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Originale"])},
    "personCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personen"])},
    "planDeliverCatalogueCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan­liefer­katalog"])},
    "planningNotificationCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bescheide"])},
    "planningNotificationOrderCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorgaben"])},
    "plotOrderCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plot­aufträge"])},
    "plotTemplateCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plot­vorlagen"])},
    "projectCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekte"])},
    "projectParticipantCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt­teilnehmer"])},
    "reportingCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporting"])},
    "reviewCycleCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüflauf"])},
    "reviewOptionsCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüf­optionen"])},
    "reviewStatusCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfstatus"])},
    "roleCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollen"])},
    "roleCaptionNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollen Neu"])},
    "settingsCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstel­lungen"])},
    "subProjectCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt­status"])},
    "supportCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "statisticCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistik"])},
    "taskCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgaben"])},
    "doneTaskCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erledigte Aufgaben"])},
    "topMenuLoggedInCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angemeldet als"])},
    "topMenuSettingsCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
    "topMenuAccessCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugangsdaten"])},
    "topMenuPersonalDataCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Profil"])},
    "topMenuInfoCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen öffnen"])},
    "topMenuUserCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzermenü öffnen"])},
    "topMenuLogoutCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])}
  },
  "plot": {
    "fileIcons": {
      "pdfToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pdf-Planvorschau"])},
      "pngToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Png-Planvorschau"])},
      "compareToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planvergleich mit vorhergehender Version"])},
      "saveToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei lokal speichern"])}
    },
    "orderAddress": {
      "caption": {
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsempfänger Anlegen"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsempfänger Bearbeiten"])}
      },
      "edit": {
        "clientCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundennummer"])},
        "organisationalUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abteilung/Projekt"])},
        "contactPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansprechperson"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])}
      }
    },
    "task": {
      "pageCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plotauftrag"])},
      "clientCodeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundennummer: "])},
      "jobNumberLabelN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auftragsnummer: "])},
      "positionCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellte Positionen"])},
      "items": {
        "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fläche"])},
        "pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seiten"])},
        "deliveryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferung an"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
        "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswahl"])},
        "countContent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Stk"])},
        "areaContent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("area")), " m²"])},
        "typeAutomatic": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automatisch generiert durch Prüfknoten ", _interpolate(_named("reviewCycleNodeName"))])},
        "typeManual": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Manuell generiert durch ", _interpolate(_named("personGivenName")), " ", _interpolate(_named("personSurName"))])}
      },
      "areaSums": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flächensummen"])},
      "monochromeSum": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["S/W ", _interpolate(_named("sum"))])},
      "colorSum": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Farbe ", _interpolate(_named("sum"))])},
      "likeTemplateSum": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wie dargestellt ", _interpolate(_named("sum"))])},
      "totalSum": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gesamt ", _interpolate(_named("sum"))])},
      "billingAddressLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsadresse: siehe Auftraggeber"])},
      "deliveryDateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liefertermin:"])},
      "kindOfDeliveryLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Art der Lieferung: ", _interpolate(_named("kindOfDelivery"))])},
      "additionalInfoLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zusätzliche Informationen: ", _interpolate(_named("comment"))])},
      "plotOrderAcceptanceResultCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferbestätigung der oben gewählten Positionen"])},
      "reviewCyclePlotResultCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auftragsbestätigung"])},
      "commentLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemerkung"])},
      "ok": {
        "plotOrderAcceptanceNotFinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["annehmen"])},
        "plotOrderAcceptanceFinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erledigt ok"])},
        "reviewCyclePlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ok"])}
      },
      "notOk": {
        "plotOrderAcceptanceNotFinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ablehnen"])},
        "plotOrderAcceptanceFinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erledigt nicht ok"])},
        "reviewCyclePlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nicht ok"])}
      },
      "laterButtonCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annehmen / Später erledigen"])},
      "nowButtonCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annehmen und erledigen"])},
      "finishButtonCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erledigen"])},
      "acceptanceButtonCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferung bestätigen"])},
      "pngDialogTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Plan ", _interpolate(_named("documentVersionName"))])},
      "jobNumberLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Auftragsnummer: ", _interpolate(_named("jobNumber"))])}
    },
    "template": {
      "edit": {
        "caption": {
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie den Typ der Plotvorlage"])},
          "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plotvorlage manueller Plotauftrag"])},
          "projectParticipation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plotvorlage automatischer Plotauftrag - Empfänger Projektteilnehmer"])},
          "reviewCycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plotvorlage automatischer Plotauftrag - Empfänger Prüflaufposition"])}
        },
        "label": {
          "templateResolver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
          "basedOnReviewCycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basiert auf Prüflauf"])}
        },
        "billing": {
          "addToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Rechnungsempfänger anlegen"])},
          "editToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsempfänger bearbeiten"])}
        }
      },
      "item": {
        "list": {
          "paper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papier"])},
          "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farbe"])},
          "margin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falten"])},
          "mirror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiegeln"])},
          "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skalieren"])},
          "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl"])},
          "addToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine neue Position hinzufügen"])},
          "deleteToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Position entfernen"])},
          "projectParticipationTarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferung an"])},
          "reviewCycleNodeTarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An alle Projektteilnehmer des Prüfknotens"])}
        }
      },
      "list": {
        "itemCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positionen"])},
        "deliveryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferung an Projektteilnehmer"])},
        "ofNode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["des Knotens"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plotvorlage bearbeiten"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plotvorlage löschen"])},
        "reallyDeleteQuestion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie die Plotvorlage ", _interpolate(_named("name")), " wirklich löschen?"])}
      },
      "page": {
        "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plotvorlagen"])}
      },
      "paperQuality": {
        "laser80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laserplot 80g/m2"])},
        "laser90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laserplot 90g/m2"])},
        "inkjet80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tintenstrahlplot 80g/m2"])},
        "inkjet90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tintenstrahlplot 90g/m2"])}
      },
      "color": {
        "monochrome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s/w"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farbe"])},
        "likeTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wie dargestellt"])}
      },
      "kindOfDelivery": {
        "deliveryServiceContractor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zustelldienst Auftragnehmer"])},
        "post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post"])},
        "upsParcelService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS Paketdienst"])},
        "veloExpress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velo Express"])},
        "byCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selbstabholung"])}
      },
      "margin": {
        "noFold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht falten"])},
        "fold20mmEdge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falten mit 20mm Heftrand"])},
        "foldWithoutEdge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falten ohne Heftrand"])}
      },
      "scale": {
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keine"])},
        "a0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A0"])},
        "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A1"])},
        "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A2"])},
        "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A3"])},
        "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A4"])}
      },
      "prop": {
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Informationen"])},
        "billingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsempfänger"])},
        "kindOfDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art der Lieferung"])},
        "plotter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auftragnehmer"])}
      },
      "resolver": {
        "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["manueller Plotauftrag"])},
        "projectParticipation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["automatischer Plotauftrag - Empfänger Projektteilnehmer"])},
        "reviewCycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["automatischer Plotauftrag - Empfänger Prüflaufposition"])}
      }
    }
  },
  "project": {
    "participation": {
      "noMainParticipantError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Für die Rolle ", _interpolate(_named("role")), " der Organisation ", _interpolate(_named("organisation")), " ist kein Verantwortlicher definiert."])},
      "page": {
        "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektteilnehmer"])},
        "newButtonCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektteilnehmer hinzufügen"])},
        "mailToAllToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail an alle Projektteilnehmer"])},
        "mailToAllTargetText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Alle Projektteilnehmer des Projekts ", _interpolate(_named("code")), " ", _interpolate(_named("name"))])},
        "countLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Projektteilnehmer gefunden"])}
      },
      "list": {
        "subProjectCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlaubte Projektstati"])},
        "organisation": {
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Projektbeteiligungen bearbeiten"])},
          "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektbeteiligung hinzufügen"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Rollen dieser Organisation löschen"])},
          "reallyDeleteQuestion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie wirklich alle Projektbeteiligungen der Organisation ", _interpolate(_named("organisation")), " löschen?"])},
          "showHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungshistorie aller Projektbeteiligungen anzeigen"])},
          "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein E-Mail an alle Projektbeteiligten in dieser Organisation schicken"])}
        },
        "deputy": {
          "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellvertreter hinzufügen"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellvertreter löschen"])},
          "confirmDelete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie den Stellvertreter \"", _interpolate(_named("surName")), " ", _interpolate(_named("givenName")), "\" wirklich entfernen?"])},
          "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein E-Mail an den Stellvertreter schicken"])},
          "addSelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuellen Benutzer als Stellvertreter eintragen"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen zum Stellvertreter"])},
          "offerParticipationsOfAllOrganisations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisationsübergreifend"])}
        },
        "mainParticipant": {
          "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein E-Mail an den Verantwortlichen schicken"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen zum Verantwortlichen"])},
          "missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACHTUNG: Kein Verantwortlicher definiert!"])}
        },
        "allowedStatuses": {
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlaubte Projektstati bearbeiten."])},
          "restrictNotAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist leider nicht möglich, den Zugriff auf diesen Projektstatus einzuschränken.  Grund: Der Projektteilnehmer hat in diesem Projektstatus bereits aktive Zuteilungen (bspw. Objektblätter, Prüflauf, Benachrichtigungen, Plotaufträge)"])}
        },
        "role": {
          "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein E-Mail an alle Projektbeteiligten schicken"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Rolle löschen"])},
          "reallyDeleteQuestion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie die Projektbeteiligung mit der Rolle ", _interpolate(_named("role")), " der Organisation ", _interpolate(_named("organisation")), " wirklich löschen?"])}
        },
        "participantHistory": {
          "dialogTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Teilnehmer Änderungshistorie (\"", _interpolate(_named("organisation")), "\" - \"", _interpolate(_named("role")), "\")"])},
          "participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnehmer"])},
          "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])},
          "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis"])},
          "changedByOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geändert von (am)"])},
          "remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmerkung"])},
          "noHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Änderungshistorie gefunden."])}
        },
        "delete": {
          "adminNonBuekMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Projektteilnehmer konnte nicht gelöscht werden, da mindestens ein Projektteilnehmer mit der Rolle 'Projektleiter'  bzw. 'Projektadministrator' existieren muss."])},
          "adminBuekMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Projektteilnehmer konnte nicht gelöscht werden, da mindestens ein Projektteilnehmer mit der Rolle 'Bescheidüberwachung Administrator' existieren muss."])},
          "plotTaskMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Projektteilnehmer konnte nicht gelöscht werden, da noch offene Plotaufträge existieren."])},
          "dialogCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektteilnehmer in Verwendung"])},
          "headerMessageFoundReferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Projektteilnehmer konnte nicht gelöscht werden, da Referenzen gefunden wurden."])},
          "headerMessageReleased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erst nachdem diese Referenzen gelöscht wurden, kann der Projektteilnehmer zum Löschen markiert werden."])},
          "headerMessageReleasedHasUnreleased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Damit der Projektteilnehmer endgültig gelöscht wird, müssen anschließend alle zugehörigen Objektlisten freigegeben werden."])},
          "headerMessageNotReleased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erst nachdem diese Referenzen gelöscht wurden kann der Projektteilnehmer gelöscht werden"])},
          "foundReferencesHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folgende Referenzen wurden gefunden"])},
          "roleLine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Eingeteilt in einer Objektblattposition mit der Rolle ", _interpolate(_named("roleName"))])},
          "objectLine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Objekt ", _interpolate(_named("objectCode")), " ", _interpolate(_named("objectName"))])},
          "objectPlannerLine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Objektblatt ", _interpolate(_named("objectPlannerCode")), " ", _interpolate(_named("objectPlannerArea"))])},
          "subProjectLine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Projektstatus ", _interpolate(_named("subProjectCode")), " ", _interpolate(_named("subProjectName"))])},
          "plotOrderTemplateLine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Plotvorlage ", _interpolate(_named("plotOrderTemplateName"))])},
          "realmLine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Einteilung in einem Prüfblatt für den Fachbereich ", _interpolate(_named("realmName"))])},
          "roleShortLine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rolle ", _interpolate(_named("roleName"))])},
          "cycleLine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prüfblatt ", _interpolate(_named("reviewCycleInstanceName")), " (", _interpolate(_named("reviewCycleCellInstanceName")), " - ", _interpolate(_named("reviewCycleNodeInstanceName")), ")"])},
          "emailLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingeteilt als Empfänger für Email-Benachrichtigungen"])},
          "plotLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingeteilt als Empfänger in einer Vorlage für Plotaufträge"])},
          "plotCustomerLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingeteilt als Auftraggeber für Plotaufträge"])},
          "plotContractorLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingeteilt als Auftragnehmer für Plotaufträge"])},
          "plotTemplateLine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Plotvorlage ", _interpolate(_named("plotOrderTemplateName"))])},
          "attachedReviewLine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Eingeteilt als optionaler Prüfer für Plan ", _interpolate(_named("documentVersionName"))])},
          "taskRealmLine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fachbereich ", _interpolate(_named("realmDescription"))])},
          "deleteOptionCaptionReleasedUnreleased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisch alle Referenzen entfernen und zum Löschen markieren"])},
          "deleteOptionCaptionReleased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektteilnehmer zum Löschen markieren"])},
          "deleteOptionCaptionElse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisch alle Referenzen entfernen und Projektteilnehmer löschen"])},
          "deleteOptionDescUnreleased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Projektteilnehmer wird automatisch aus allen Prüf- und Objektblättern entfernt."])},
          "deleteOptionDescAttached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Einteilungen als Optionaler Prüfer werden gelöscht."])},
          "deleteOptionDescNotificationNotPlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es werden alle Einteilungen als Emfänger von Benachrichtigungsemails ausgetragen."])},
          "deleteOptionDescPlotNotNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es werden alle Einteilungen als Emfänger bzw. Auftraggeber von Plotaufträgen ausgetragen."])},
          "deleteOptionDescNotificationPlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es werden alle Einteilungen als Emfänger von Benachrichtigungsemails sowie alle Einteilungen als Emfänger bzw. Auftraggeber von Plotaufträgen ausgetragen."])},
          "deleteOptionDescReleasedReferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anschließend wird dieser Projektteilnehmer zum Löschen markiert.  Nachdem die Objektlisten aller betroffenen Projektstati freigegeben wurden, wird dieser Projektteilnehmer automatisch endgültig gelöscht."])},
          "deleteOptionDescReleasedReferencesWarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACHTUNG: Das Löschen dieser Einteilungen kann nicht rückgängig gemacht werden!"])},
          "deleteOptionDescReferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anschließend wird dieser Projektteilnehmer gelöscht."])},
          "deleteOptionDescReferencesWarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACHTUNG: Das Löschen dieses Projektteilnehmers und dessen Einteilungen kann nicht rückgängig gemacht werden!"])},
          "deleteOptionDescElse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachdem die Objektlisten aller betroffenen Projektstati freigegeben wurden, wird dieser Projektteilnehmer automatisch gelöscht."])},
          "cancelOptionCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht löschen"])},
          "cancelOptionDescHasReleased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen und den Projektteilnehmer nicht zum Löschen markieren"])},
          "cancelOptionDescElse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen und den Projektteilnehmer nicht löschen."])},
          "subProjectsCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folgende Projektstati sind betroffen"])},
          "deleteBuekCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit dem Löschen des Projektteilnehmers fortfahren"])},
          "deleteBuekDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wird versucht den Projektteilehmer zu löschen. Wenn das Löschen scheitert, scheint dieser weiterhin in der Liste auf. Es erfolgt kein weiterer Hinweis."])}
        }
      },
      "edit": {
        "roleHeading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rolle ", _interpolate(_named("role"))])},
        "mailFlag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pläne per Post zusenden"])},
        "emailTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan versenden als Dateianhang"])},
        "blockedStatusesTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Projektteilnehmer hat auf folgende Projektstati keinen Zugriff"])},
        "allowedSubProjectsTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Projektteilnehmer hat nur auf folgende Projektstati Zugriff"])},
        "addRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle hinzufügen"])},
        "roleConflict": {
          "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollenkonflikt"])},
          "subCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorsicht!"])},
          "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben die Rolle zumindest eines Projektteilnehmers geändert.  Dieser Projektteilnehmer wurde bereits in Prüfblättern für Planprüfungen eingeteilt."])},
          "consequences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie die Änderungen speichern, werden diese Einteilungen gelöscht!"])},
          "cellCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zelle"])}
        },
        "switchOrg": {
          "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wollen Sie wirklich "])},
          "oneOrg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["die Rolle ", _interpolate(_named("role")), " der Organisation ", _interpolate(_named("organisation"))])},
          "postfix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" übertragen?"])}
        }
      }
    },
    "list": {
      "subProject": {
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diesen Projektstatus bearbeiten."])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diesen Projektstatus löschen."])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Projektstatus hinzufügen."])},
        "read-only-tool-tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modus von 'Lesen/Schreiben' auf 'Nur lesen' ändern"])},
        "read-write-tool-tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modus von 'Nur lesen' auf 'Lesen/Schreiben' ändern"])},
        "reallyDeleteQuestion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie den Projektstatus ", _interpolate(_named("subProject")), " wirklich löschen?"])},
        "cannotDeletePlotOrders": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Es ist leider nicht möglich, den Projektstatus ", _interpolate(_named("subProject")), " zu löschen oder zu invalidieren.  Grund: Es gibt Plotaufträge, die entweder offen sind, oder sich mit einem anderen Projektstatus überschneiden."])},
        "jumpToObjectList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektliste"])},
        "jumpToReviewCycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfblätter"])},
        "jumpToPlanDeliverCatalogue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planlieferkatalog"])},
        "jumpToDocumentList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planverzeichnis"])},
        "jumpToAsBuildObjList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestandsplanung Objekte"])},
        "jumpToAsBuildDocList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestandsplanung Dokumente"])},
        "readOnlyQuestion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie den Projektstatus ", _interpolate(_named("subProject")), " wirklich auf den Modus 'Nur lesen' setzen?"])},
        "readWriteQuestion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie den Projektstatus ", _interpolate(_named("subProject")), " wirklich auf den Modus 'Lesen/Schreiben' setzen?"])}
      },
      "noSubProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Projektstatus vorhanden"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Projekt bearbeiten"])},
      "invisibleAccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt nicht sichtbar setzen"])},
      "readWriteAccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt sichtbar setzen - lesen/schreiben"])},
      "readOnlyAccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt sichtbar setzen - nur lesen"])},
      "invisibleAccessQuestion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie das Projekt ", _interpolate(_named("code")), " ", _interpolate(_named("name")), " wirklich nicht sichtbar setzen?"])},
      "readWriteAccessQuestion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie das Projekt ", _interpolate(_named("code")), " ", _interpolate(_named("name")), " wirklich sichtbar setzen im Lese/Schreibmodus?"])},
      "readOnlyAccessQuestion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie das Projekt ", _interpolate(_named("code")), " ", _interpolate(_named("name")), " wirklich sichtbar setzen im Nur-Lese-Modus?"])},
      "makeProjectAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Projektadministrator einteilen"])},
      "reallyMakeProjectAdminQuestion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie sich wirklich für das Projekt ", _interpolate(_named("code")), " ", _interpolate(_named("name")), " als Projektadministrator einteilen, um Rechte im Projektkontext zu erhalten?"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Projekt löschen."])},
      "reallyDeleteQuestion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie das Projekt ", _interpolate(_named("code")), " ", _interpolate(_named("name")), " wirklich löschen?"])},
      "cannotDeletePlotOrders": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Es ist leider nicht möglich, den Projektstatus ", _interpolate(_named("subProject")), " zu löschen oder zu invalidieren.  Grund: Es gibt offene Plotaufträge.  Das Projekt als Ganzes kann daher weder gelöscht noch invalidiert werden."])},
      "participationsToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste der Projektteilnehmer öffnen"])}
    },
    "edit": {
      "editHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt Bearbeiten"])},
      "newHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt Erstellen"])},
      "nameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektbezeichnung"])},
      "missingCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben sie eine Projektnummer an."])},
      "duplicateCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Projektnummer ist schon belegt."])},
      "missingName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben sie einen Namen an."])},
      "missingRoute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben sie einen Streckennamen an."])},
      "missingLocalisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben sie eine Lokalisierung an."])},
      "missingdocumentNumberPartGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben sie ein Plannummernsystem an."])},
      "missingFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben sie von km an."])},
      "missingTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben sie zu km an."])},
      "badInputFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von km muss eine Zahl sein."])},
      "badInputTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zu km muss eine Zahl sein."])},
      "principalProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptprojekt"])},
      "route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strecke"])},
      "stretch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschnitt"])},
      "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von km"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zu km"])},
      "localisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokalisierung"])},
      "documentNumberPartGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plannummernsystem"])},
      "createdTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erzeugt am"])},
      "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erzeugt von"])},
      "ignorePlotOrderReceivedSteps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plotaufträge ohne Lieferbestätigung als abgeschlossen markieren"])},
      "withParticipantsSubProjectRestriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugriffsbeschränkung von Projektteilnehmern auf ausgewählte Projektstatus ermöglichen"])},
      "allowedResultOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionale Prüfoptionen für Prüfungen"])},
      "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Speichern des Projekts ist fehlgeschlagen.  Bitte beachten Sie, dass das Löschen von bereits in Verwendung befindlichen Objektblattdefinitionen nicht möglich ist."])},
      "projectHolidays": {
        "missingName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben sie einen Namen an."])},
        "missingFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben sie ein von Datum an."])},
        "missingTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben sie ein zu Datum an."])}
      },
      "objectPlannerDefinitions": {
        "tabCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektblattdefinition / Benachrichtigungen"])},
        "positionsCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positionen"])},
        "notificationColumnCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungsoptionen für Dokumentstatusänderungen"])},
        "notificationColumnSubCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei Eintreten der hier gewählten Aktionen, erhält der jeweilige Rollentyp eine automatische e-mail Benachrichtigung, welche nicht unterdrückt werden kann."])},
        "notificateReleasedPositiv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigegeben"])},
        "notificateReleasedNegativ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigabe abgelehnt"])},
        "notificateInvalidatedVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültig - Status X"])},
        "notificateInvalidatedAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültig - Status U"])},
        "notificateDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload widerrufen"])},
        "notificateRevised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Revision ersetzt"])},
        "addToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position hinzufügen"])},
        "reallyDeleteQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wollen Sie diese Positionsdefinition unwiderbringlich löschen?"])},
        "countLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Positionen gefunden"])}
      }
    },
    "page": {
      "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekte"])},
      "addProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Projekt hinzufügen"])},
      "countLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Projekte gefunden"])}
    }
  },
  "subProject": {
    "page": {
      "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektstatus"])}
    },
    "edit": {
      "numberOutOfRange": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bitte wählen sie eine Zahl zwischen ", _interpolate(_named("from")), " und ", _interpolate(_named("to")), "."])},
      "numberInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen sie eine gültige Zahl aus."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kürzel / Bezeichnung"])},
      "codeNumberAlreadyExists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die von Ihnen ausgewählte Kombination ", _interpolate(_named("codeNumber")), " aus Kürzel und Ziffer ist bereits in Verwendung.  Bitte wählen Sie eine andere."])}
    }
  },
  "task": {
    "list": {
      "deputyLine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["stv. für ", _interpolate(_named("givenName")), " ", _interpolate(_named("surName"))])},
      "extendedDeputyLine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("mainGivenName")), " ", _interpolate(_named("mainSurName")), " (", _interpolate(_named("mainOrganisation")), ")\nstv. ", _interpolate(_named("ownGivenName")), " ", _interpolate(_named("ownSurName")), " (", _interpolate(_named("ownOrganisation")), ")"])},
      "extendedDeputyLineDONE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("mainGivenName")), " ", _interpolate(_named("mainSurName")), " (", _interpolate(_named("mainOrganisation")), ")\nsigniert: ", _interpolate(_named("ownGivenName")), " ", _interpolate(_named("ownSurName")), " (", _interpolate(_named("ownOrganisation")), ")"])},
      "documentCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan"])},
      "doneDateCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erledigt am"])},
      "doneResultCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnis"])},
      "objectCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekt"])},
      "projectCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt"])},
      "networkCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzwerk"])},
      "responsibleCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verantwortlicher"])},
      "responsibleDONECaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verantwortlicher / signiert"])},
      "responsibleCaptionOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verantwortlicher [für Organisation]"])},
      "reviewDateCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geprüft am"])},
      "reviewResultCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnis"])},
      "reviewStatusCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüflaufstatus"])},
      "statusCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Aufgabenstatus"])},
      "taskCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe"])},
      "deadline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(Termin: ", _interpolate(_named("deadline")), ")"])},
      "contractualDeadline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(vereinbarter Termin: ", _interpolate(_named("deadline")), ")"])},
      "computedDeadline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(berechneter Termin: ", _interpolate(_named("deadline")), ")"])},
      "inSignatureFolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(in Signaturmappe)"])},
      "toSignatureFolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Signaturmappe"])},
      "overrideReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Säumige Prüfung übernehmen"])},
      "editOriginalDocumentRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeitung des Originaldokuments anfordern"])},
      "receivePlotOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plotauftrag entgegennehmen"])},
      "plotOrderDeliveryConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plotauftrag Lieferbestätigung"])},
      "deleteTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe löschen"])},
      "reallyDeleteTaskQuestion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie die Aufgabe ", _interpolate(_named("taskName")), " zum Plan ", _interpolate(_named("documentName")), " wirklich löschen?"])},
      "uploadedCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum upload"])},
      "taskCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " von ", _interpolate(_named("total")), " Aufgaben gefunden"])},
      "taskRevision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Planrevision"])}
    },
    "page": {
      "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgaben"])},
      "myTasksTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Aufgaben"])},
      "myTasksTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu Meine Aufgaben wechseln"])},
      "signatureFolderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signaturmappe"])},
      "signatureFolderTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Signaturmappe wechseln"])},
      "surveillanceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminüberwachung"])},
      "surveillanceTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Terminüberwachung wechseln"])},
      "doneTasksTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erledigt"])},
      "doneTasksTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Erledigte Aufgaben wechseln"])}
    },
    "search": {
      "modeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansicht"])},
      "myTasksItemLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Aufgaben"])},
      "organisationLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in Organisation"])},
      "signatureFolderItemLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signaturmappe"])},
      "doneTasksItemLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erledigte Aufgaben"])},
      "belatedTasksItemLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminüberwachung"])},
      "openTasksItemLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offene Aufgaben"])},
      "dateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termin"])},
      "deputyLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["als"])},
      "deputyOptions": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
        "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptverantwortlich"])},
        "deputy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellvertretend"])}
      },
      "deputyDONEOptions": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
        "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von mir"])},
        "deputy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von anderen"])}
      },
      "toLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis"])},
      "fromInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vor einem Monat"])},
      "fromIntervalInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben sie ein gültiges Anfangsdatum an."])},
      "toInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in 3 Tagen"])},
      "toIntervalInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben sie ein gültiges Enddatum an."])},
      "toggleButton": {
        "asDeputy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["als Stellvertreter"])},
        "asMainParticipant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["als Hauptverantwortlicher"])},
        "inOrganisation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["für ", _interpolate(_named("organisationName"))])},
        "fromTs": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["vom ", _interpolate(_named("fromTs"))])},
        "toTs": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["bis ", _interpolate(_named("toTs"))])},
        "showSearchFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchfelder zeigen: "])}
      }
    }
  },
  "original": {
    "page": {
      "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Originale"])},
      "countLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Anforderungen gefunden"])}
    },
    "provideOriginal": {
      "page": {
        "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Originaldokument bereitstellen"])},
        "provideRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anforderung bereitstellen"])},
        "denyRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anforderung ablehnen"])},
        "selectOriginalFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Originaldatei wählen"])},
        "uploadHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweis: Die Datei wird erst beim Signieren hochgeladen."])},
        "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signieren"])}
      }
    },
    "list": {
      "fromMyself": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von mir"])},
      "toMyself": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["an mich"])},
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["angenommen"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abgelehnt"])},
      "deleteRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage löschen"])},
      "askDeleteRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wollen Sie die Anfrage sowie eine möglicherweise bestehende Aufgabe wirklich löschen?"])}
    },
    "search": {
      "fromMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von mir"])},
      "toMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An mich"])},
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angenommen"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgelehnt"])}
    }
  },
  "review": {
    "upload": {
      "page": {
        "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel/Inhalt"])},
        "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massstab"])},
        "noDestination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben sie ein Ziel an."])},
        "noDestinationFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurde keine Zielzelle gefunden. Bitte prüfen Sie, ob diesem Plan ein Prüfblatt zugeordnet ist und kontaktieren Sie in diesem Fall die Projektadministration."])},
        "reviewMatrix": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prüferzuteilung ― Knoten ", _interpolate(_named("node"))])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planupload"])},
        "reuploadBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zuletzt"])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemerkungen zum Upload / Änderungsvermerke"])},
        "statements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellungnahme zu diesem Upload"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorhergehende Prüfungen kommentieren"])},
        "showAttachedReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Prüfer zuteilen"])},
        "hideAttachedReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuteilung weiterer Prüfer verbergen"])},
        "attachedReviewsCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planprüfung - erweiterte Prüferzuteilung"])},
        "attachedReviewsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu den im Prüfblatt definierten Prüfern, können optional weitere Prüfer ausschließlich für diesen Plan eingeteilt werden."])},
        "documentReferenceDeleteWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wollen Sie die verwendete Unterlage wirklich löschen?"])},
        "notYetUploadedWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es konnten noch nicht alle Daten übertragen werden.  Bitte warten Sie kurz, und versuchen Sie es dann erneut."])}
      },
      "preview": {
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei"])},
        "localName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateiname lokal"])},
        "rotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotationswinkel"])},
        "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Größe"])},
        "pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seiten"])},
        "alternativeDocumentNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternative Plannummer"])},
        "changeDocumentNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versionsnummer ändern"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Planinterpretation"])},
        "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wichtiger Hinweis"])},
        "alertText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte führen Sie immer anhand der Vorschau eine visuelle Kontrolle des Uploads durch, auch wenn der Status der Planinterpretation ein positives Ergebnis ausgibt. Sind hier z.B. der Plan verdreht bzw. die Signaturfelder nicht an den vorgesehenen Positionen dargestellt, führen Sie den Upload bitte trotzdem durch und kontaktieren Sie anschließend den c.des-Support unter Anführung folgender Angaben: Netzwerk, Projekt, Projektstatus und Plannummer. Daraufhin erfolgt die Anpassung der Darstellung des Planes direkt im c.des durch die Entwickler."])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschau"])},
        "noDestination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen sie ein Ziel aus."])},
        "uploadError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Es ist ein Fehler beim Hochladen des Dokumentes aufgetreten. ", _interpolate(_named("reload"))])},
        "retryUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu versuchen"])},
        "noFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben sie eine Datei an."])},
        "passwordTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planupload Signieren"])},
        "foundTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plankopf wurde erfolgreich gefunden"])},
        "notFoundTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plankopf wurde nicht gefunden"])},
        "notFoundButObligatoryTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plankopf wurde nicht gefunden, ist aber erforderlich. Bitte prüfen Sie Ihren Plan!"])},
        "notFoundLabelIgnored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plankopf ignorieren"])},
        "notFoundLabelRenderLabelOutsidePlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard-Plankopf als Zusatzblatt hinzufügen"])},
        "notFoundLabelAsIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plankopf unverändert rendern"])},
        "notFoundLabelTrue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
        "label": {
          "outsideMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Plankopf wird aufgrund einer Prüfblatteinstellung außerhalb des Plans gerendert."])},
          "outside": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurde kein Planschild gefunden, der Plankopf wird außerhalb des Plans gerendert."])},
          "labelIgnored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Suche nach dem Plankopf wird nicht durchgeführt, da 'Plankopfsuche deaktivieren' ausgewählt wurde."])},
          "noLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diesem Prüfblatt ist kein Musterplankopf zugeordnet"])},
          "noTypeSupportWithLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Beim Dateityp \"", _interpolate(_named("type")), "\" wird die Plankopfsuche nicht unterstützt."])},
          "noTypeSupportWithoutLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Beim Dateityp \"", _interpolate(_named("type")), "\" wird die Größenberechnung nicht unterstützt."])},
          "finishedWithLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Suche nach dem Plankopf wurde beendet."])},
          "finishedWithoutLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Größenberechnung wurde erfolgreich beendet."])},
          "BAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sehr zweifelhafte Übereinstimmung"])},
          "DOUBTFUL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweifelhafte Übereinstimmung"])},
          "GOOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gefunden - gute Übereinstimmung"])},
          "PERFECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gefunden - sehr gute Übereinstimmung"])},
          "NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht gefunden"])}
        },
        "pngPreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailplanvorschau öffnen"])}
      },
      "matrix": {
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Fehler beim laden der Prüferzuteilung aufgetreten."])},
        "attachedHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweis: Es können zusätzliche Prüfer eingeteilt werden.  Diese befinden sich am Ende der Matrix."])},
        "noNodeWithPositionsButAttachedPostions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurde kein nachfolgender Prüfknoten mit gültigen Prüferzuteilungen gefunden.  Sie können falls möglich optionale zusätzliche Prüfer für diesen Prüfknoten wählen oder alternativ den Projektadministrator kontaktieren."])},
        "noNodeWithPositions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurde kein Prüfknoten mit gültigen Prüferzuteilungen gefunden. Bitte kontaktieren Sie den Projektadministrator."])}
      },
      "reference": {
        "attach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateianhang hinzufügen"])},
        "referenceDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument/Plan referenzieren"])},
        "selectDocumentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswahl des in \"Verwendeten Unterlagen\" zu verknüpfenden Dokumentes/Planes"])},
        "missingReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen dieses Feld ausfüllen."])}
      },
      "logTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["log"])},
      "statements": {
        "realm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellungnahme zu"])}
      }
    },
    "review": {
      "cellHeading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cellName")), " Version ", _interpolate(_named("versionPart"))])},
      "header": {
        "infoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Informationen"])},
        "fromTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["km von-bis"])},
        "pdfPreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pdf-Planvorschau"])},
        "pngPreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["png-Planvorschau"])},
        "comparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planvergleich mit vorhergehender Version"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei lokal speichern"])},
        "reviewProtocolPdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfprotokoll PDF"])}
      },
      "nav": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigation"])}
      },
      "data": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bisherige Prüfungen"])},
        "showNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Prüfung auswählen"])},
        "showAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamte Prüfliste anzeigen"])},
        "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochgeladen am"])},
        "by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["durch"])},
        "deputyWithOrganisation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["stv. für ", _interpolate(_named("givenName")), " ", _interpolate(_named("surName")), " (", _interpolate(_named("organisation")), ")"])},
        "deputyWithoutOrganisation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["stv. für ", _interpolate(_named("givenName")), " ", _interpolate(_named("surName"))])},
        "notReviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe durchgewunken ― Kommentar:"])},
        "overridenBy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Verspätete Aufgabe übernommen von ", _interpolate(_named("givenName")), " ", _interpolate(_named("surName")), " ― Kommentar:"])},
        "durationLine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Start:", _interpolate(_named("start")), " Ende:", _interpolate(_named("end"))])},
        "commentHeading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("node")), " - Kommentare zu vorhergehenden Prüfungen"])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar zur Prüfung"])},
        "reviewCycleCommentOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnis"])},
        "noEntries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Einträge"])},
        "stateXCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument wurde für ungültig erklärt"])}
      },
      "entry": {
        "heading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Meine Prüfung – ", _interpolate(_named("node"))])},
        "overridingHeading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Meine Prüfung – ", _interpolate(_named("node")), " — Säumige Prüfung übernehmen"])},
        "noResultOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen ein Resultat angeben."])},
        "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofort Signieren"])},
        "signPasswordError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Prüfung konnte nicht signiert werden.  Haben Sie das korrekte Passwort eingegeben?"])},
        "signChosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgewählte Aufgaben signieren"])},
        "signError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Fehler aufgetreten.  Die Aufgaben wurden nicht signiert.  Haben Sie das korrekte Passwort eingegeben?"])},
        "saveToSignatureFolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern und in die Signaturmappe"])},
        "validFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gültig für Version"])},
        "commentNodeHeading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Version ", _interpolate(_named("version")), " – ", _interpolate(_named("node"))])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentare zu vorhergehenden Prüfungen"])},
        "signTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfung Signieren"])},
        "attachmentAt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("givenName")), " ", _interpolate(_named("surName")), " am ", _interpolate(_named("date")), ")"])},
        "breakWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit dieser Entscheidung unterbrechen Sie den aktuellen Prüflauf.  Der Planer muss zum Fortsetzen des Prüflaufs eine neue Version hochladen."])}
      }
    },
    "label": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musterplankopf"])},
      "labelContents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planschildinhalte"])},
      "assignedReviewCycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugeordneter Prüflauf"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
      "list": {
        "pageCaption": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Musterplanköpfe der Organisation ", _interpolate(_named("organisation"))])},
        "hideInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktive Musterplanköpfe verstecken"])},
        "addLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Musterplankopf hinzufügen"])},
        "editLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diesen Musterplankopf bearbeiten"])},
        "deleteLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diesen Musterplankopf löschen"])},
        "copyToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieren"])},
        "copyQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wollen Sie eine Kopie dieses Musterplankopfs mit dem Postfix '~' und einer Zahl 0-99 anlegen?"])},
        "copyFailedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Kopieren des Musterplankopfs ist leider fehlgeschlagen."])},
        "renderableOutsideCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional"])},
        "reallyDeleteLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie den Musterplankopf ", _interpolate(_named("name")), " wirklich löschen?"])}
      },
      "edit": {
        "pageCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musterplankopf bearbeiten"])},
        "lastChangedOn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zuletzt geändert: ", _interpolate(_named("dateFormatString"))])},
        "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nie"])},
        "addLabelContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planschildinhalt hinzufügen"])},
        "deleteLabelContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planschildinhalt löschen"])},
        "countLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Planschildinhalte gefunden"])}
      },
      "page": {
        "countLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Musterplanköpfe gefunden"])}
      }
    },
    "labelContent": {
      "edit": {
        "caption": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Planschildinhalt zu Musterplankopf ", _interpolate(_named("label"))])},
        "primaryReviewCycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primärer Prüflauf"])},
        "alternativeReviewCycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternativer Prüflauf"])},
        "copySelectedTextFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgewählte Textfelder vom rechts gewähltem Prüflauf in aktiven alternativen Prüflauf kopieren"])},
        "rotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotation"])},
        "hpgl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hpgl/pdf-Datei"])},
        "original": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Originaldatei"])},
        "signatureCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signatur Textfelder"])},
        "documentVersionCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentversion Textfelder"])},
        "freeTextCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freitext Textfelder"])},
        "renderableOutsideLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionales Planschild"])},
        "renderableOutsideToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird beim Hochladen kein Planschild erkannt, wird diese Planschildvorlage am Rand des Plans gerendert."])},
        "defaultLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])}
      },
      "relatedProjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugehörige Projekte"])},
      "list": {
        "previewToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musterplankopf Vorschau"])},
        "downloadToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planervorlage herunterladen"])},
        "documentTypesToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumenttyp zuweisen"])},
        "defaultNotDeletableMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist nicht möglich, den Standard-Planschildinhalt zu löschen."])},
        "reallyDeleteLabelContent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie den Planschildinhalt ", _interpolate(_named("name")), " wirklich löschen?"])}
      },
      "documentType": {
        "pageCaption": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Plantypen zu Planschildinhalt ", _interpolate(_named("labelContentName"))])},
        "codeColumnCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kürzel"])},
        "nameColumnCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
        "chooseColumnCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswahl"])}
      }
    },
    "labelTextField": {
      "realm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FB#"])},
      "lowerLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["links unten"])},
      "upperRight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rechts oben"])},
      "font": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Font"])},
      "fontSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-größe"])},
      "fontStyleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fontstyle"])},
      "alignmentLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausrichtung"])},
      "content": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument-/Plannummer"])},
        "documentVersionPartA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentversionsnummer Teil A"])},
        "documentVersionPartB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentversionsnummer Teil B"])},
        "asBuildName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plannummer des Quelldokumentes"])},
        "altName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternative Dokument-/Plannummer"])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument-/Plankommentar"])},
        "principalProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptprojekt"])},
        "projectCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektcode"])},
        "objectType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektart"])},
        "projectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektname"])},
        "projectRoute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt Strecke"])},
        "projectStretch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt Abschnitt"])},
        "subProjectCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektstatuscode"])},
        "subProjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektstatusname"])},
        "documentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantitel"])},
        "projectKms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["km von - bis (Projekt)"])},
        "objectKms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["km von - bis (Objekt)"])},
        "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maßstab"])},
        "objectCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektcode"])},
        "objectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektname"])},
        "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaddatum"])},
        "uploadSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploadsignatur"])},
        "trackerUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracker URL"])}
      },
      "fontStyle": {
        "regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal"])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fett"])},
        "italic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursiv"])},
        "boldItalic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fett und kursiv"])}
      },
      "alignment": {
        "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links"])},
        "center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittig"])},
        "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechts"])}
      }
    }
  },
  "passwordDialog": {
    "missingPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen ihr Passwort eingeben."])},
    "assignedReviewCycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugeordneter Prüflauf"])},
    "labelContents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planschildinhalte"])}
  },
  "roleConflictInfo": {
    "dialogTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollenkonflikt aufgetreten"])}
  }
}